import type { ItemModel, DataItemModel } from 'o365-dataobject';
import type { default as NodeData, INodeDataLevelConfiguration } from './DataObject.NodeData.ts';

import { Item as DataItem } from 'o365-dataobject';

/** Node item used in NodeData structures */
export class NodeItem<T extends ItemModel = ItemModel> {
    /** Unique key used for retrieving the full data item */
    protected _fetchKey?: string | number;
    /** Get the NodeData instance for this item */
    protected _getNodeData: () => NodeData<T>;
    /** Get the configuration this NodeItem belongs to */
    protected _getConfiguration: () => INodeDataLevelConfiguration<T>;
    /** Function used to fill in the DataItem value */
    protected _refreshItem?: (pKey: string | number) => Promise<DataItemModel<T> | undefined>;
    /** Function called before inner load. Used for placeholder items */
    protected _beforeLoad?: (pNode: NodeItem<T>) => Promise<void>;
    /** Function used to fill in the summary values for non DataItem nodes */
    protected _getSummaryValues?: (pDetails: NodeItem<T>[]) => Promise<T>;
    /** Loading promise created when isLoading is accessed */
    protected _loadingPromise?: Promise<DataItemModel<T> | T | undefined | void>;
    /** Get combined filter string for loading details of this item */
    protected _getFilterString?: () => string;
    protected _canCreateNodes: boolean;
    /** Added setters/getters passthrough keys for the inner data item */
    protected _itemKeys: Set<string> = new Set();
    protected _dataItem?: DataItemModel<T>;
    protected _summaryItem?: T;
    /** Field used by NodeColumn to display a value */
    protected _displayField?: string;
    protected _partialItem?: Partial<T>;

    protected _cachedCount?: number;

    /** Get the parent node of this item */
    getParent: () => NodeItem<T> | undefined = () => undefined;
    expanded: boolean = false;
    details: NodeItem<T>[] = [];
    level: number = 0;
    /** Index of this node in the flattened out display array */
    displayIndex: number | undefined;
    protected _key: string[] = [];
    protected _error?: string;
    protected _isSelected = false;
    get displayValue() { return this._displayField ? (this as any)[this._displayField] as any : undefined; }
    get displayField() { return this._displayField; }
    /** Group key in string form */
    get key() {
        return this._key.map(x => `${x}`).join('/');
    }
    /** Parent path key */
    get parentKey() {
        return this._key.slice(0, -1).map(x => `${x}`).join('/');
    }
    /** Parent id of the item */
    get parentId() { return this._key.at(-2); }
    /** Group key in array form */
    get keyArray() {
        return this._key;
    }
    get hasNodes() {
        return this.details.length > 0 || this._summaryItem?._count;
    }
    get count(): number {
        if (this._cachedCount) {
            return this._cachedCount;
        }

        this._cachedCount = this._summaryItem?._count || this.details.length + this.details.reduce((sum, node) => sum + node.count, 0);
        return this._cachedCount!;
    }
    get hasSelected() {
        return this.hasNodes && this.details.some(x => x.isSelected);
    }
    /** Indicates that this Node has the ability to create detail or sibling nodes */
    get canCreateNodes() { return this._canCreateNodes; }
    set canCreateNodes(pValue) { this._canCreateNodes = pValue; }
    /** Indicator for other controls used when determining if received item is DataItem or NodeItem */
    get isNode() { return true; }
    /** DataItem of this node */
    get dataItem() { return this._dataItem; }
    /** Summary values of this node */
    get summaryItem() { return this._summaryItem; }
    /** Indicates that this Node is a summary item and has no real DataItem attached to it */
    get isSummaryItem() { return this._getSummaryValues != null || this._summaryItem != null; }
    /** Get combined filter string for loading details of this item */
    get getFilterString() { return this._getFilterString; }
    /** Unique key used to retrieve values for this item */
    get fetchKey() { return this._fetchKey; }

    // --- DataItemModel Passthrough properties ---
    get primKey() { return this._dataItem?.primKey }
    get isLoading() {
        if (this._summaryItem) {
            return false
        } else if (this._dataItem == null) {
            this.loadItem();
            return true;
        } else {
            return this._dataItem.isLoading;
        }
    }
    isLoaded = false;
    get error() { return this._error ?? this._dataItem?.error; }
    get index() { return this._dataItem?.index; }
    get dataObjectId() { return this._dataItem?.dataObjectId; }
    get appId() { return this._dataItem?.appId; }
    get oldValues() { return this._dataItem?.oldValues; }
    get item() { return this._dataItem?.item; }
    get defaultValues() { return this._dataItem?.defaultValues; }
    get hasChanges() { return this._dataItem?.hasChanges; }
    get isDeleting() { return this._dataItem?.isDeleting; }
    get isEmpty() { return this._dataItem?.isEmpty; }
    get isNewRecord() { return this._dataItem?.isNewRecord; }
    get isBatchRecord() { return this._dataItem?.isBatchRecord; }
    get loadingPromise() { return this._dataItem?.loadingPromise ?? this._loadingPromise; }
    get isSaving() { return this._dataItem?.isSaving; }
    get state() { return this._dataItem?.state; }
    get changes() { return this._dataItem?.changes; }
    get current() { return this._dataItem?.current ?? false; }
    set current(value: boolean) {
        if (this._dataItem) {
            this._dataItem.current = value;
        }
    }
    get isSelected() { return this._dataItem?.isSelected ?? this._isSelected; }
    set isSelected(value) {
        this._isSelected = value;
        if (this._dataItem) {
            this._dataItem.isSelected = value;
        }
        const nodeData = this._getNodeData();
        if (!nodeData.disableDetailsMultiSelect) {
            this.details.forEach(node => node.isSelected = value);
        }
    }
    get disableSaving() { return this._dataItem?.disableSaving ?? false; }
    set disableSaving(pValue) {
        if (this._dataItem) {
            this._dataItem.disableSaving = pValue;
        }
    }

    save() { return this._dataItem?.save(); }
    delete() { return this._dataItem?.delete(); }
    cancelChanges() { return this._dataItem?.cancelChanges(); }
    reset() { return this._dataItem?.reset(); }
    extendItem(...args: Parameters<DataItem<T>['extendItem']>) { return this._dataItem?.extendItem(...args); }
    updateError(...args: Parameters<DataItem<T>['updateError']>) { return this._dataItem?.updateError(...args); }
    removeError(...args: Parameters<DataItem<T>['removeError']>) { return this._dataItem?.removeError(...args); }

    /** Properties passthrough */
    get properties() { return this._dataItem?.['properties'] ?? this._summaryItem?.properties }
    get propertiesRows() { return this._dataItem?.['propertiesRows']; }
    get propertiesRowsArray() { return this._dataItem?.['propertiesRowsArray']; }
    get propertiesJSON() { return this._dataItem?.['propertiesJSON']; }
    get isPropertiesLoading() { return this._dataItem?.['isPropertiesLoading']; }
    // --------------------------------------------

    // --- Compatibility for previous node structures ---
    get o_hasDetails() { return this.hasNodes; }
    get o_level() { return this.level; }
    get o_groupHeaderRow() { return this.isSummaryItem; }
    // ---------

    constructor(pOptions: {
        key?: any[];
        fetchKey?: string | number;
        getNodeData: () => NodeData<T>;
        getConfiguration: () => INodeDataLevelConfiguration<T>;
        refreshItem?: (pKey: string | number) => Promise<DataItemModel<T> | undefined>
        getSummaryValues?: (pDetails: NodeItem<T>[]) => Promise<T>,
        getFilterString?: () => string,
        beforeLoad?: (pNode: NodeItem<T>) => Promise<void>,
        summaryItem?: T,
        passthroughKeys?: string[],
        displayField?: string,
        item?: DataItemModel<T>,
        partialItem?: Partial<T>
    }) {
        if (pOptions.key) {
            this._key = pOptions.key;
        }
        if (pOptions.item) {
            this._dataItem = pOptions.item;
        }
        if (pOptions.fetchKey) {
            this._fetchKey = pOptions.fetchKey;
        }
        if (pOptions.summaryItem) {
            this._summaryItem = pOptions.summaryItem;
            this._assignSummaryPropertiesProxy(this._summaryItem);
        }
        if (pOptions.displayField) {
            this._displayField = pOptions.displayField;
        }
        if (pOptions.beforeLoad) {
            this._beforeLoad = pOptions.beforeLoad;
        }
        if (pOptions.getFilterString) {
            this._getFilterString = pOptions.getFilterString;
        }
        this._getNodeData = pOptions.getNodeData;
        this._getConfiguration = pOptions.getConfiguration;
        this._refreshItem = pOptions.refreshItem;
        this._getSummaryValues = pOptions.getSummaryValues;
        this._partialItem = pOptions.partialItem;
        if (pOptions.passthroughKeys) {
            pOptions.passthroughKeys.forEach(key => this._itemKeys.add(key));
        }

        this._canCreateNodes = false;
    }

    expand() {
        if (this.expanded) { return; }
        const nodeData = this._getNodeData();
        this.expanded = true;
        nodeData.update();
    }

    collapse() {
        if (!this.expanded) { return; }
        const nodeData = this._getNodeData();
        this.expanded = false;
        nodeData.update();
    }

    expandTo() {
        const expandTraverse = (node: NodeItem<T>) => {
            node.expanded = true;
            const parent = node.getParent();
            if (parent) {
                expandTraverse(parent);
            }
        };
        const parent = this.getParent();
        if (parent) {
            expandTraverse(parent);
            this._getNodeData().update();
        }
        this._getNodeData().events.emit('ExpandedToNode', this);
    }

    getConfiguration() {
        return this._getConfiguration();
    }

    async loadItem() {
        if (this._loadingPromise) { return; }
        if (this._beforeLoad) {
            this._loadingPromise = this._beforeLoad(this);
            await this._loadingPromise;
        }
        if (this._refreshItem) {
            if (!this.fetchKey) { return; }
            this._loadingPromise = this._refreshItem(this.fetchKey);
            const item = await this._loadingPromise;
            if (item instanceof DataItem) {
                this._dataItem = item;
                this._dataItem.isSelected = this._isSelected;
                // TODO(Augustas): Think of a better way to get node from data item
                (this._dataItem as any)['_getNode'] = () => this;

                this._canCreateNodes = this._getConfiguration().canCreateNodes(this);
                this.isLoaded = true;
            } else {
                this._error = 'Failed to retrieve item';
            }
        } else if (this._getSummaryValues) {
            this._loadingPromise = this._getSummaryValues(this.details);
            const item = await this._loadingPromise;
            if (item) {
                this._summaryItem = item;
            } else {
                this._error = 'Failed to retrieve summary values';
            }
        }
    }
    
    indent() {
        const nodeData = this._getNodeData();
        const config = this.getConfiguration();
        const parent = this.getParent();
        if (parent) {
            const index = parent.details.findIndex(x => x.key === this.key);
            if (index === -1 || parent.details[index - 1] == null) { return; }
            parent.details.splice(index, 1);
            const sibling = parent.details[index - 1];
            this.getParent = () => sibling;
            config.updateNodeParent(this, sibling);
            this.level = sibling.level + 1;
            sibling.details.push(this);
            sibling.expanded = true;
        } else {
            const index = nodeData.root.findIndex(x => x.key === this.key);
            if (index === -1 || nodeData.root[index - 1] == null) { return; }
            nodeData.root.splice(index, 1);
            const sibling = nodeData.root[index - 1];
            this.getParent = () => sibling;
            config.updateNodeParent(this, sibling);
            this.level = sibling.level + 1;
            sibling.details.push(this);
            sibling.expanded = true;
        }
        const traverseDetails = (node: NodeItem<T>, level: number) => {
            node.level = level;
            if (node.hasNodes) {
                node.details.forEach(detail => traverseDetails(detail, level + 1));
            }
        };
        this.details.forEach(detail => traverseDetails(detail, this.level + 1));
        nodeData.update();
        nodeData.events.emit('AfterIndent', this);
        this.dataItem?.save();
    }

    outdent() {
        const nodeData = this._getNodeData();
        const config = this.getConfiguration();
        const parent = this.getParent();
        if (parent == null) { return; }
        const nodeIndex = parent.details.findIndex(x => x.key === this.key);
        if (nodeIndex === -1) { return; }
        parent.details.splice(nodeIndex, 1);

        const parentScope = parent.getParent();
        if (parentScope) {
            const index = parentScope.details.findIndex(x => x.key === parent.key);
            if (index === -1 || parentScope.details[index] == null) { return; }
            this.getParent = () => parentScope;
            config.updateNodeParent(this, parentScope);
            this.level = parentScope.level + 1;
            parentScope.details.splice(index + 1, 0, this);
            parentScope.expanded = true;
        } else {
            const index = nodeData.root.findIndex(x => x.key === parent.key);
            if (index === -1 || nodeData.root[index] == null) { return; }
            this.getParent = () => undefined;
            config.updateNodeParent(this, parentScope);
            this.level = 0;
            nodeData.root.splice(index + 1, 0, this);
        }
        const traverseDetails = (node: NodeItem<T>, level: number) => {
            node.level = level;
            if (node.hasNodes) {
                node.details.forEach(detail => traverseDetails(detail, level + 1));
            }
        };
        this.details.forEach(detail => traverseDetails(detail, this.level + 1));
        nodeData.update();
        nodeData.events.emit('AfterOutdent', this);
        this.dataItem?.save();
    }

    addDetail(pItem?: T | DataItemModel<T> | NodeItem<T>) {
        if (!this.canCreateNodes || this.isLoading) { return; }
        const config = this.getConfiguration();

        let node: NodeItem<T> | null = null;
        if (pItem?.isNode) {
            node = pItem as NodeItem<T>;
        } else {
            node = config.createNode({
                item: pItem as T | DataItemModel<T> | undefined
            });
            node.level = this.level + 1;
        }
        if (node) {
            node.getParent = () => this;
            config.updateNodeParent(node, this);
            this.details.push(node);
            if (!this.expanded) { this.expanded = true; }
            const nodeData = this._getNodeData();
            nodeData.update();
            nodeData.events.emit('NodeAdded', node);

        }
    }

    addSibling(pItem?: T | DataItemModel<T> | NodeItem<T>, pOptions?: {
        above?: boolean
    }) {
        if (!this.canCreateNodes || this.isLoading) { return; }
        const config = this.getConfiguration();

        let node: NodeItem<T> | null = null;
        if (pItem?.isNode) {
            node = pItem as NodeItem<T>;
        } else {
            node = config.createNode({
                item: pItem as T | DataItemModel<T> | undefined
            });
            node.level = this.level;
        }
        if (node) {
            const parent = this.getParent();
            node.getParent = () => parent;
            const insertAbove = pOptions?.above ?? false;
            if (parent) {
                config.updateNodeParent(node, parent);
                const index = parent.details.findIndex(x => x.key === this.key);
                parent.details.splice(insertAbove ? index : index + 1, 0, node);
                const nodeData = this._getNodeData();
                nodeData.update();
                nodeData.events.emit('NodeAdded', node);
            } else {
                const nodeData = this._getNodeData();
                config.updateNodeParent(node);
                const index = nodeData.root.findIndex(x => x.key === this.key);
                nodeData.root.splice(insertAbove ? index : index + 1, 0, node);
                nodeData.update();
                nodeData.events.emit('NodeAdded', node);
            }
        }
    }

    hasSibling(pBefore = false) {
        const parent = this.getParent();
        if (parent) {
            const index = parent.details.findIndex(x => x.key === this.key);
            if (index === -1) { return false; }
            return pBefore ? parent.details[index - 1] != null : parent.details[index + 1] != null;
        } else {
            const nodeData = this._getNodeData();
            const index = nodeData.root.findIndex(x => x.key === this.key);
            if (index === -1) { return false; }
            return pBefore ? nodeData.root[index - 1] != null : nodeData.root[index + 1] != null;
        }
    }


    /** Update getters and setters passthrough to the inner DataItem */
    updateItemPassthrough() {
        this._itemKeys.forEach(key => {
            Object.defineProperty(this, key, {
                get() { return this._dataItem?.[key] ?? this._summaryItem?.[key] ?? this._partialItem?.[key]; },
                set(value: any) {
                    if (this._dataItem) {
                        this._dataItem[key] = value;
                    }
                }
            });
        });
        //     if (this._dataItem == null) {
        //         this._itemKeys.forEach((key) => {
        //             delete (this as any)[key];
        //         });
        //         this._itemKeys.clear();
        //         return;
        //     }
        //     Object.keys(this._dataItem.item).forEach(key => {
        //         if (this._itemKeys.has(key)) { return; }
        //         Object.defineProperty(this, key, {
        //             get() { console.log(this, this._dataItem); return this._dataItem[key]; },
        //             set(value: any) { console.log(this, this[key]); this._dataItem[key] = value; }
        //         });
        //         this._itemKeys.add(key);
        //     });
        // }
    }

    loadValues() {
        if (this.isLoading) {
            return this._loadingPromise ?? Promise.resolve();
        } else {
            return this._loadingPromise;
        }
    }

    /** Load this item and all of its details */
    async loadAll() {
        await this.loadValues();
        const promises = this.details.map(detail => {
            detail.loadAll();
        });
        await Promise.all(promises);
    }

    /** Trigger a recount of details */
    resetCount() {
        this._cachedCount = undefined;
    }

    protected _assignSummaryPropertiesProxy(pItem: any) {
        const properties = {};
        const propertiesProxy = new Proxy(properties, {
            get(target, prop, reciever) {
                if (typeof prop == 'string' && pItem[`Property.${prop}`]) {
                    return pItem[`Property.${prop}`];
                }
                return Reflect.get(...arguments);
            }
        });
        pItem.properties = propertiesProxy;
    }

    protected _recalculateFieldValue(pField: string) {
        delete this._calculationsCache[pField];
        const parent = this.getParent();
        if (parent) { parent._recalculateFieldValue(pField); }
    }

    protected _recalculateValues(pField: string) {
        this._calculationsCache = {};
        const parent = this.getParent();
        if (parent) { parent._recalculateValues(pField); }
    }

    protected _calculationsCache: Record<string, any> = {};
}

type PublicConstructor<T, P extends unknown[]> = new (...args: P) => T;

type NodeItemModelType<T extends ItemModel> = Omit<
    NodeItem<T>,
    never
>;

export interface ICalculatedField<T extends ItemModel> {
    name: string,
    generator: (pItem: NodeItem<T>) => Generator<unknown, undefined, unknown>,
    dependantFields: string[],
}

/** Get NodeItemModel class */
export function getNodeItemModel<T extends ItemModel>(pOptions: {
    prefix: string,
    calculatedFields?: ICalculatedField<T>[],
    passthroughKeys?: string[]
}): PublicConstructor<NodeItemModelType<T>, ConstructorParameters<typeof NodeItem<T>>> {
    const NodeItemModel = class extends NodeItem<T> {
        static registeredKeys = new Set<string>();

        static updatePassthrough(pKeys: string[]) {
            const dependenciesMap = this.calculatedFieldsDependencies;
            for (const key of pKeys) {
                this.registeredKeys.add(key);
                Object.defineProperty(this.prototype, key, {
                    get(this: any) {
                        return this.dataItem?.[key] ?? this._summaryItem?.[key] ?? this._partialItem?.[key];
                    },
                    set(this: any, value: any) {
                        if (this._dataItem) {
                            (this._dataItem as any)[key] = value;

                            if (dependenciesMap.has(key)) {
                                for (const field of dependenciesMap.get(key)!) {
                                    this._recalculateFieldValue(field)
                                }
                            }
                        }
                    }
                })
            }
        }

        static calculatedFieldsDependencies = new Map<string, Set<string>>();

        static assignCalculatedFields(pCalculatedFields: ICalculatedField<T>[]) {
            for (const field of pCalculatedFields) {
                this.registeredKeys.add(field.name);
                for (const dependantField of field.dependantFields) {
                    if (!this.calculatedFieldsDependencies.has(dependantField)) {
                        this.calculatedFieldsDependencies.set(dependantField, new Set());
                    }
                    this.calculatedFieldsDependencies.get(dependantField)!.add(field.name);
                }
                Object.defineProperty(this.prototype, field.name, {
                    get(this: NodeItem<T>) {
                        if (!(field.name in (this as any)._calculationsCache)) {
                            const generator = field.generator(this);
                            let result = generator.next();
                            let finalValue: any = undefined;

                            while (!result.done) {
                                result = generator.next(result.value);
                            }
                            finalValue = result.value;

                            (this as any)._calculationsCache[field.name] = finalValue;
                        }

                        return (this as any)._calculationsCache[field.name];
                    }
                });
            }
        }

        constructor(...args: ConstructorParameters<typeof NodeItem<T>>) {
            super(...args);
        }

        
        hasOwnProperty(pKey: string) {
            if (NodeItemModel.registeredKeys.has(pKey)) {
                return true;
            }
            
            return Object.prototype.hasOwnProperty.call(this, pKey);
        }
    }

    if (pOptions.passthroughKeys && pOptions.passthroughKeys.length) {
        NodeItemModel.updatePassthrough(pOptions.passthroughKeys);
    }

    if (pOptions.calculatedFields?.length) {
        NodeItemModel.assignCalculatedFields(pOptions.calculatedFields);
    }

    Object.defineProperty(NodeItemModel, 'name', { value: `${pOptions.prefix}_NodeItemModel` });
    return NodeItemModel;
}
